import {
  GET_ITEM,
  GET_ITEMS,
  CLEAR_ITEM,
  ITEMS_ERROR,
  ITEM_CREATED,
  ITEM_UPDATED,
  ITEM_DELETED
} from '../actions/types';

const initialState = {
  currentitem: null,
  items: [],
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ITEM:
      return {
        ...state,
        currentitem: payload,
        loading: false
      };
    case GET_ITEMS:
      return {
        ...state,
        items: payload,
        loading: false
      };
    case CLEAR_ITEM:
    case ITEM_CREATED:
    case ITEM_UPDATED:
    case ITEM_DELETED:
      return {
        ...state,
        currentitem: null,
        loading: false
      };
    case ITEMS_ERROR:
      return {
        ...state,
        errors: payload,
        loading: false
      };
    default:
      return state;
  }
}
