// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/backgrounds/home-1920.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".main-content-normal .refimage {\n  position: relative;\n  float: left;\n  width: 100%;\n  height: calc(100vh - 60px);\n  background-color: #ccc;\n  background-repeat: no-repeat;\n  background-position: center center;\n  background-size: cover;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.main-content-normal .refimage .caption {\n  position: absolute;\n  bottom: 15%;\n  right: 10%;\n  width: auto;\n  height: auto;\n  padding: 50px;\n  background-color: rgba(0, 0, 0, 0.8);\n  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.85);\n  color: var(--lightest-color);\n}\n.main-content-normal .refimage .caption h1 {\n  margin: 0;\n  font-weight: 300;\n  font-size: 3em;\n  text-align: center;\n  text-transform: uppercase;\n}\n.main-content-normal .refimage .caption hr {\n  border-width: 4px;\n  margin: 1.5em auto;\n}\n.main-content-normal .refimage .caption .subtext {\n  font-family: 'Montserrat', sans-serif;\n  font-size: 1.5em;\n  text-align: center;\n}\n@media screen and (max-width: 880px) {\n  .main-content-normal .refimage .caption {\n    width: 80%;\n    left: 10%;\n    right: 10%;\n    font-size: 0.75em;\n  }\n}\n@media screen and (max-width: 440px) {\n  .main-content-normal .refimage .caption {\n    padding: 25px;\n    font-size: 0.5em;\n  }\n}\n", ""]);
// Exports
module.exports = exports;
