import React from 'react';
import { Link } from 'react-router-dom';

const FooterEN = () => {
  return (
    <footer className='footer'>
      <div className='copyright'>Copyright © 2019</div>
      <nav className='footer-menu'>
        <ul>
          <li>
            <Link to='/privacy-policy'>Privacy Policy</Link>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default FooterEN;
