import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/routing/PrivateRoute';

import Alert from './components/layout/Alert';

import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Admin from './components/admin/Admin';
import EditItem from './components/items/EditItem';
import CreateItem from './components/items/CreateItem';

import Landing from './components/pages/Landing';
import Home from './components/pages/Home';
import Rolunk from './components/pages/Rolunk';
import AboutUs from './components/pages/AboutUs';
import Galeria from './components/pages/Galeria';
import Gallery from './components/pages/Gallery';
import Kapcsolat from './components/pages/Kapcsolat';
import ContactUs from './components/pages/ContactUs';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import './App.css';
import './styles/Basic.css';
import './styles/Layout.css';
import './styles/Backgrounds.css';
import './styles/NormalContent.css';
import './styles/ScrollContent.css';
import './styles/Home.css';
import './styles/ItemInfo.css';
import './styles/Admin.css';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Alert />
          <Switch>
            <Route exact path='/' component={Landing} />
            <Route exact path='/home' component={Home} />
            <Route exact path='/rolunk' component={Rolunk} />
            <Route exact path='/about-us' component={AboutUs} />
            <Route exact path='/galeria' component={Galeria} />
            <Route exact path='/gallery' component={Gallery} />
            <Route exact path='/kapcsolat' component={Kapcsolat} />
            <Route exact path='/contact-us' component={ContactUs} />
            <Route exact path='/register' component={Register} />
            <Route exact path='/cms/login' component={Login} />
            <PrivateRoute exact path='/cms/admin' component={Admin} />
            <PrivateRoute
              exact
              path='/cms/create-item'
              component={CreateItem}
            />
            <PrivateRoute
              exact
              path='/cms/edit-item/:id'
              component={EditItem}
            />
          </Switch>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
