import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import slugify from 'slugify';
import { createItem, updateItem, deleteItem } from '../../actions/item';
import { setAlert } from '../../actions/alert';
import PlusImage from '../../img/plus.png';
import Spinner from '../../img/spinner.gif';
import Resizer from 'react-image-file-resizer';

import BackAdmin from '../admin/BackAdmin';

import '../../styles/Form.css';

const ItemForm = ({
  item: { currentitem },
  titleText,
  history,
  createItem,
  updateItem,
  deleteItem
}) => {
  /* STATES */

  // Form State
  const [formData, setFormData] = useState({
    nev: '',
    name: '',
    lifespan: '',
    cim: '',
    title: '',
    technika: '',
    technique: '',
    meretek: '',
    dimensions: '',
    highlight: false,
    weight: null,
    imagename: null,
    thumbname: null,
    upload: false
  });

  // Image State
  const [imageData, setImageData] = useState({
    image: null,
    block: ' empty',
    imagePreviewUrl: PlusImage
  });

  /* HOOKS */

  // Miután az onSubmit beállította a kép nevét, ez elküldi
  useEffect(() => {
    if (formData.upload) {
      const sendImage = new FormData();

      sendImage.append('imagename', formData.imagename);
      sendImage.append('thumbname', formData.thumbname);
      sendImage.append('image', imageData.image);

      if (currentitem) {
        console.log('update');
        updateItem(currentitem._id, formData, history, sendImage);
      } else {
        console.log('create');
        createItem(formData, history, sendImage);
      }
    }
  }, [formData]);

  // A kép kinyerése megjelenítéshez, ha változik a file input tartalma
  useEffect(() => {
    if (imageData.image) setImage();
  }, [imageData.image]);

  //Formanyomtatvány kitöltése state-ből
  useEffect(() => {
    setFormData({
      nev: currentitem && currentitem.name ? currentitem.name.hu : '',
      name: currentitem && currentitem.name ? currentitem.name.en : '',
      lifespan: currentitem && currentitem.lifespan ? currentitem.lifespan : '',
      cim: currentitem && currentitem.title ? currentitem.title.hu : '',
      title: currentitem && currentitem.title ? currentitem.title.en : '',
      technika:
        currentitem && currentitem.technique ? currentitem.technique.hu : '',
      technique:
        currentitem && currentitem.technique ? currentitem.technique.en : '',
      meretek:
        currentitem && currentitem.dimensions ? currentitem.dimensions.hu : '',
      dimensions:
        currentitem && currentitem.dimensions ? currentitem.dimensions.en : '',
      highlight: currentitem && currentitem.highlight ? true : false,
      weight: currentitem && currentitem.weight ? currentitem.weight : null,
      imagename:
        currentitem && currentitem.imagename ? currentitem.imagename : '',
      thumbname:
        currentitem && currentitem.thumbname ? currentitem.thumbname : '',
      upload: false
    });

    if (currentitem && currentitem.imagename)
      setImageData({
        ...imageData,
        imagePreviewUrl: `/gallery/${currentitem.imagename}?${Math.random()}`,
        block: ''
      });
  }, [currentitem]);

  /* FUNCTIONS */

  // File megjeleníthető formátumra konvertálása a frontenden
  const setImage = file => {
    const reader = new FileReader();

    reader.onloadend = e => {
      const res = e.target.result;

      // Image object
      /* const img = new Image();

      img.src = res;

      img.onload = function() {
        const w = this.width;
        const h = this.height;
      }; */

      setImageData({ ...imageData, imagePreviewUrl: res, block: ' loader' });
    };

    reader.readAsDataURL(imageData.image);
  };

  /* LISTENERS */

  // Form elemeinek változása
  const onChange = e =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

  // A fájlmező változása
  const onImageUpload = e => {
    setImageData({ ...imageData, magePreviewUrl: Spinner, block: ' loading' });

    e.preventDefault();

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = e => {
      if (file.type === 'image/jpeg') {
        Resizer.imageFileResizer(
          file,
          1920,
          1080,
          'JPEG',
          90,
          0,
          uri => {
            var newimage = new File([uri], file.name, {
              type: file.type,
              lastModifiedDate: file.lastModifiedDate
            });
            setImageData({ ...imageData, image: newimage });
          },
          'blob'
        );
      } else {
        setAlert('Rossz képformátum, csak JPG lehet.', 'danger');
      }
    };

    reader.readAsDataURL(file);
  };

  // Submit
  const onSubmit = async e => {
    e.preventDefault();

    let sendfile = null;

    if (imageData.image) {
      sendfile = new FormData();
      sendfile.append('image', imageData.image);
    }

    const baseName = `${slugify(formData.nev, '_')}-${slugify(
      formData.cim,
      '_'
    )}`;

    const fileName = !formData.imagename
      ? `${baseName}.jpg`
      : formData.imagename;
    const thumbName = !formData.thumbname
      ? `${baseName}_thumb.jpg`
      : formData.thumbname;

    if (!currentitem && !sendfile) {
      console.log('Nincs kép');
      setAlert('Kötelező képet feltölteni a tételhez', 'danger');
    } else {
      setFormData({
        ...formData,
        imagename: fileName,
        thumbname: thumbName,
        upload: true
      });
    }
  };

  /* VARIABLES */
  const {
    nev,
    name,
    lifespan,
    cim,
    title,
    technika,
    technique,
    meretek,
    dimensions,
    highlight
  } = formData;

  return (
    <Fragment>
      <BackAdmin title={titleText} />
      <form className='form-area' onSubmit={e => onSubmit(e)}>
        <div
          className={`form-block image-block${imageData.block}`}
          onClick={() => document.getElementById('item-image').click()}
          style={{ backgroundImage: `url('${imageData.imagePreviewUrl}')` }}
        >
          <input
            type='file'
            name='image'
            id='item-image'
            onChange={e => onImageUpload(e)}
          />
        </div>
        <div className='form-block text-block name'>
          <input
            type='text'
            name='nev'
            placeholder='A művész neve (magyar)'
            required
            value={nev}
            onChange={e => onChange(e)}
          />
          <input
            type='text'
            name='name'
            placeholder='A művész neve (angol)'
            required
            value={name}
            onChange={e => onChange(e)}
          />
        </div>
        <div className='form-block text-block lifespan'>
          <input
            type='text'
            name='lifespan'
            placeholder='Született - meghalt (év - év)'
            required
            value={lifespan}
            onChange={e => onChange(e)}
          />
        </div>
        <div className='form-block text-block title'>
          <input
            type='text'
            name='cim'
            placeholder='A mű címe (magyar)'
            required
            value={cim}
            onChange={e => onChange(e)}
          />
          <input
            type='text'
            name='title'
            placeholder='A mű címe (angol)'
            required
            value={title}
            onChange={e => onChange(e)}
          />
        </div>
        <div className='form-block text-block technique'>
          <input
            type='text'
            name='technika'
            placeholder='Technika (magyar)'
            value={technika}
            onChange={e => onChange(e)}
          />
          <input
            type='text'
            name='technique'
            placeholder='Technika (angol)'
            value={technique}
            onChange={e => onChange(e)}
          />
        </div>
        <div className='form-block text-block dimensions'>
          <input
            type='text'
            name='meretek'
            placeholder='Méretei (magyar/cm)'
            value={meretek}
            onChange={e => onChange(e)}
          />
          <input
            type='text'
            name='dimensions'
            placeholder='Méretei (angol)'
            value={dimensions}
            onChange={e => onChange(e)}
          />
        </div>
        <div className='form-block check-standalone-block highlight'>
          <label>
            <input
              type='checkbox'
              name='highlight'
              value='1'
              checked={highlight}
              onChange={() => {
                setFormData({ ...formData, highlight: !highlight });
              }}
            />{' '}
            <span>Főoldalra kerül</span>
          </label>
        </div>
        <div className='form-block button-block'>
          {currentitem && (
            <button
              className='btn btn-danger'
              onClick={() => deleteItem(currentitem._id, history)}
            >
              Törlés
            </button>
          )}
        </div>
        <div className='form-block button-block'>
          <button className='btn btn-success'>Mentés</button>
        </div>
      </form>
    </Fragment>
  );
};

ItemForm.propTypes = {
  createItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  titleText: PropTypes.string
};

const mapStateToProps = state => ({
  item: state.item
});

export default connect(mapStateToProps, {
  createItem,
  updateItem,
  deleteItem
})(withRouter(ItemForm));
