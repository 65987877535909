import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getHighlightedItems } from '../../actions/item';
import { setLanguage } from '../../actions/language';
import { Helmet } from 'react-helmet';

import Spinner from '../layout/Spinner';
import NavbarHU from '../layout/NavbarHU';
import FooterHU from '../layout/FooterHU';
import ItemList from '../items/ItemList';
import ItemInfo from '../items/ItemInfo';

const Landing = ({
  getHighlightedItems,
  setLanguage,
  item: { currentitem, items, loading }
}) => {
  useEffect(() => {
    getHighlightedItems();
    setLanguage('hu');
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Kárai Galéria | Galéria a zsinagógánál</title>
      </Helmet>
      {currentitem && <ItemInfo />}
      <NavbarHU active='Home' />
      <section className='main-content-normal'>
        <div className='refimage'>
          <div className='caption'>
            <h1>
              <span className='highlight'>Kárai</span> Galéria
              <br />a <span className='highlight'>Dohány</span> zsinagógánál
            </h1>
            <hr />
            <div className='subtext'>
              1077&nbsp;Budapest, Wesselényi&nbsp;u.&nbsp;9.
            </div>
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : items.length > 0 ? (
          <Fragment>
            <div className='page-title'>
              <h1>Kínálatunkból</h1>
              <hr />
            </div>
            <div className='highlighted-list item-list'>
              <ItemList list={items} />
            </div>
          </Fragment>
        ) : (
          <Fragment></Fragment>
        )}
      </section>
      <FooterHU />
    </Fragment>
  );
};

Landing.propTypes = {
  getHighlightedItems: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  item: state.item
});

export default connect(mapStateToProps, { getHighlightedItems, setLanguage })(
  Landing
);
