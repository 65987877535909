import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';

const NavbarAdmin = ({ auth: { isAuthenticated, loading }, logout }) => {
  return (
    <header className='admin-header'>
      <div className='logo'>
        <span className='k-blue regular'>Kárai</span> Galéria
        <span className='smaller regular'>Admin</span>
      </div>
      <div className='logout'>
        <a href='#!' onClick={logout}>
          Logout
        </a>
      </div>
    </header>
  );
};

NavbarAdmin.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToAuth = state => ({
  auth: state.auth
});

export default connect(mapStateToAuth, { logout })(NavbarAdmin);
