import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ItemForm from '../items/ItemForm';
import NavbarAdmin from '../layout/NavbarAdmin';

import '../../styles/Basic.css';
import '../../styles/Admin.css';

const CreateItem = ({ item }) => {
  return (
    <Fragment>
      <div className='admin'>
        <NavbarAdmin />
        <section className='admin-main-content'>
          <ItemForm titleText='Új tétel hozzáadása' />
        </section>
      </div>
    </Fragment>
  );
};

CreateItem.propTypes = {
  item: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  item: state.item
});

export default connect(mapStateToProps)(CreateItem);
