import { SET_LANGUAGE } from '../actions/types';

const initialState = {
  code: ''
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LANGUAGE:
      return {
        ...state,
        code: payload
      };
    default:
      return state;
  }
}
