import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import NavbarHU from '../layout/NavbarHU';
import FooterHU from '../layout/FooterHU';
import ScrollBox from '../layout/ScrollBox';
import RolunkContent from './contents/RolunkContent';

const Rolunk = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Rólunk | Kárai Galéria</title>
      </Helmet>
      <NavbarHU active='Rólunk' />
      <section className='main-content-scroll rolunk-bg'>
        <section className='content-box'>
          <h1>Bemutatkozás</h1>
          <hr />
          <ScrollBox Component={RolunkContent} />
        </section>
      </section>
      <FooterHU />
    </Fragment>
  );
};

export default Rolunk;
