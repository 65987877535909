import axios from 'axios';
import { setAlert } from './alert';
import {
  GET_ITEM,
  GET_ITEMS,
  CLEAR_ITEM,
  ITEMS_ERROR,
  ITEM_CREATED,
  ITEM_UPDATED,
  ITEM_DELETED
} from './types';

// Get Item by ID
export const getItemById = itemId => async dispatch => {
  dispatch({ type: CLEAR_ITEM });

  try {
    const res = await axios.get(`/api/items/${itemId}`);

    dispatch({
      type: GET_ITEM,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ITEMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all item
export const getItems = () => async dispatch => {
  dispatch({ type: CLEAR_ITEM });

  try {
    const res = await axios.get('/api/items');

    dispatch({
      type: GET_ITEMS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ITEMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Highlighted items
export const getHighlightedItems = () => async dispatch => {
  dispatch({ type: CLEAR_ITEM });

  try {
    const res = await axios.get('/api/items/highlighted');

    dispatch({
      type: GET_ITEMS,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ITEMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Set item in state
export const setItem = item => async dispatch => {
  dispatch({
    type: GET_ITEM,
    payload: item
  });
};

//Clear item
export const clearItem = () => async dispatch => {
  dispatch({ type: CLEAR_ITEM });
};

// Create an item
export const createItem = (formData, history, image) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.post('/api/items', formData, config);

    if (res && image.get('image')) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      await axios.post('/api/items/image-upload', image, config);
    }

    dispatch({
      type: ITEM_CREATED
    });

    history.push('/cms/admin');

    dispatch(setAlert('A tétel rögzítve', 'success'));
  } catch (err) {
    dispatch({
      type: ITEMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Update an item
export const updateItem = (id, formData, history, image) => async dispatch => {
  console.log('image:', image.get('image'));
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(`/api/items/${id}`, formData, config);

    if (res && image.get('image') !== 'null') {
      console.log('try update image', image.get('image'));
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      await axios.post('/api/items/image-upload', image, config);
    }

    dispatch({
      type: ITEM_UPDATED
    });

    history.push('/cms/admin');

    dispatch(setAlert('A tétel frissítve', 'success'));
  } catch (err) {
    console.log(err);
    dispatch({
      type: ITEMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Upload file
export const uploadImage = file => async dispatch => {
  try {
    if (file) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      await axios.post('/api/items/image-upload', file, config);
    }
  } catch (err) {
    dispatch({
      type: ITEMS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete item
export const deleteItem = (id, history) => async dispatch => {
  if (window.confirm('Biztos, hogy törli a tételt?')) {
    try {
      const res = await axios.delete(`/api/items/${id}`);

      dispatch({ type: ITEM_DELETED });

      history.push('/cms/admin');

      dispatch(setAlert('A tétel törölve lett.'));
    } catch (err) {
      dispatch({
        type: ITEMS_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  }
};
