import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import NavbarEN from '../layout/NavbarEN';
import FooterEN from '../layout/FooterEN';
import ScrollBox from '../layout/ScrollBox';
import ContactUsContent from './contents/ContactUsContent';

const ContactUs = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Contact Us | Kárai Galéria</title>
      </Helmet>
      <NavbarEN active='Contact Us' />
      <section className='main-content-scroll kapcsolat-bg'>
        <section className='content-box'>
          <h1>Contact Us</h1>
          <hr />
          <ScrollBox Component={ContactUsContent} />
        </section>
      </section>
      <FooterEN />
    </Fragment>
  );
};

export default ContactUs;
