import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Item from './Item';

const ItemList = ({ list }) => {
  return (
    <Fragment>
      {list.length > 0 ? (
        list.map(hitem => <Item key={hitem._id} item={hitem} />)
      ) : (
        <div>Nincsenek tételek</div>
      )}
    </Fragment>
  );
};

ItemList.propTypes = {
  list: PropTypes.array.isRequired
};

export default ItemList;
