import React, { Fragment } from 'react';

const RolunkContent = () => {
  return (
    <Fragment>
      <p>Kárai Galéria - 1995 óta a műkereskedelemben</p>
      <p>
        A Kárai Galériát tulajdonosa, Kárai Lajos 2000-ben alapította Budapest
        történelmi belvárosában, a Dohány utcai zsinagóga szomszédságában.
      </p>
      <p>
        A galéria kínálatában XIX. - XX. századi magyar és külföldi, továbbá
        neves magyar kortárs festőművészek alkotásai szerepelnek.
      </p>
      <p>
        Támogatja fiatal - és kezdő festőművészek alkotásai bemutatását,
        pályájuk elindítását.
      </p>
      <p>
        Vállalja továbbá irodák, hotelek, kastélyok, éttermek, polgári lakások
        berendezését ( bútor, festmény, szőnyeg, óra, porcelán ... ),
        magángyűjtemények értékbecslését - felvásárlását.
      </p>
      <p>Festményeivel emeli tárlatok - kiállítások színvonalát.</p>
      <p>
        A festményeken kívül az üzlet kínálatában megtalálhatók Herendi
        porcelánok, Zsolnay kerámiák, és antik ezüst műtárgyak is.
      </p>
      <p>
        A galéria neves magyar és külföldi aukciósházakkal áll kapcsolatban,
        melynek köszönhetően segítséget nyújt, hogy a legmagasabb áron
        értékesíthessék műtárgyaikat.
      </p>
    </Fragment>
  );
};

export default RolunkContent;
