import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';

const NavbarEN = ({ active }) => {
  const menuItems = [
    { name: 'Home', url: '/home' },
    { name: 'About Us', url: '/about-us' },
    { name: 'Gallery', url: '/gallery' },
    { name: 'Contact Us', url: '/contact-us' }
  ];

  const [mainMenu, setMainMenu] = useState(false);

  const onClick = e => {
    setMainMenu(!mainMenu);
  };

  return (
    <header className='header'>
      <div className='logo'>
        <span className='k-orange regular'>Kárai</span> Gallery
      </div>
      <nav className={mainMenu ? 'header-nav open' : 'header-nav'}>
        <ul className='lang'>
          <li>
            <Link to='/'>hu</Link>
          </li>
          <li>
            <Link to='/home' className='active'>
              en
            </Link>
          </li>
        </ul>
        <ul className='main-menu'>
          {menuItems.map(mitem => (
            <li key={uuid()}>
              <Link
                to={mitem.url}
                className={active === mitem.name ? 'active' : ''}
              >
                {mitem.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div className='hamburger-menu' onClick={e => onClick(e)}></div>
    </header>
  );
};

NavbarEN.propTypes = {
  active: PropTypes.string
};

export default NavbarEN;
