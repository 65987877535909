export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_ITEM = 'GET_ITEM';
export const GET_ITEMS = 'GET_ITEMS';
export const CLEAR_ITEM = 'CLEAR_ITEM';
export const ITEMS_ERROR = 'ITEMS_ERROR';
export const ITEM_CREATED = 'ITEM_CREATED';
export const ITEM_UPDATED = 'ITEM_UPDATED';
export const ITEM_DELETED = 'ITEM_DELETED';
export const SET_LANGUAGE = 'SET_LANGUAGE';
