import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const ContactUsContent = () => {
  return (
    <Fragment>
      <div className='content-text-middle'>
        <p>Address: 1077 Budapest, Wesselényi u. 9.</p>

        <p>
        Phone: <a href='phone:+3613442728'>+36 1 344 2728</a>
        </p>

        <p>
        Mobil: <a href='phone:+36209245370'>+36 20 924 5370</a>
        </p>

        <p>
          E-mail:{' '}
          <a href='mailto:karaigaleria@karaigaleria.hu'>
            karaigaleria@karaigaleria.hu
          </a>
        </p>

        <p>
        Open: <br />
        Monday-Friday from 10 a.m. to 6 p.m.
        </p>
      </div>

      <div className='map'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2695.6420900676258!2d19.059431915883923!3d47.496885379177606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dc42859692fb%3A0xc11a4f32d7fd96af!2sBudapest%2C%20Wessel%C3%A9nyi%20u.%209%2C%201077!5e0!3m2!1shu!2shu!4v1575755686956!5m2!1shu!2shu'
          width='100%'
          frameBorder='0'
          style={{ border: 0 }}
          allowFullScreen=''
        ></iframe>
      </div>
    </Fragment>
  );
};

export default ContactUsContent;
