import React, { Fragment } from 'react';

const AboutUsContent = () => {
  return (
    <Fragment>
      <p>Kárai Gallery - since 1995 in business</p>
      <p>
        We established Kárai Gallery in 2000. The gallery is located in the
        heart of Budapest ,next to Dohány street synagogue.
      </p>
      <p>
        We offer Hungarian and foreign paintings from the XIX.-XX. century also
        paintings from famous local contemporary artists.
      </p>
      <p>
        We support and help to start young and beginner artists. We suit
        ,furnish undertake offices, hotels, castles, restaurants, civil flats
        with furnitures,paintings,carpets, clocks, porcelains. We also buy and
        valutazione private collections.
      </p>
      <p>We raise the level with our paintings in exhibitions.</p>
      <p>
        You can find Herendy porcelains, Zsolnay ceramics and antique silver art
        pieces too.
      </p>
      <p>
        Our gallery is in partnership with renowned, famous Hungarian and
        foreign auction houses, thanks to which we can help you to sell your
        works of art at the highest prices.
      </p>
    </Fragment>
  );
};

export default AboutUsContent;
