import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setItem } from '../../actions/item';

const Item = ({ item, language, setItem }) => {
  const onClick = e => {
    e.preventDefault();
    setItem(item);
  };
  return (
    <div className='item' id={item._id} onClick={e => onClick(e)}>
      <div className='item-image-frame'>
        <div
          className='item-image'
          style={
            item.thumbname && {
              backgroundImage: `url('/gallery/${item.thumbname}')`
            }
          }
        ></div>
      </div>
      <div className='item-data'>
        <div className='item-data-name'>{item.name[language.code]}</div>

        <hr />
        <div className='item-data-title'>{item.title[language.code]}</div>
      </div>
    </div>
  );
};

Item.propTypes = {
  item: PropTypes.object.isRequired,
  lang: PropTypes.string,
  setItem: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  language: state.language
});

export default connect(mapStateToProps, { setItem })(Item);
