import React, { Fragment, useState } from 'react';
import { Redirect } from 'react-router-dom';
//import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import Alert from '../layout/Alert';

import '../../styles/Basic.css';
import '../../styles/Login.css';

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    name: '',
    password: ''
  });

  const { name, password } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();

    const success = await login(name, password);

    if (!success) {
      setFormData({ ...formData, password: '' });
    }
  };

  // Redirect if logged in

  if (isAuthenticated) {
    return <Redirect to='/cms/admin' />;
  }

  return (
    <Fragment>
      <div className='wrapper'>
        <div className='login-box'>
          <div className='login-box-header'>
            <span className='k-blue regular'>Kárai</span> Galéria
            <span className='smaller regular'> Admin</span>
          </div>
          <div className='login-box-form'>
            <div className='error'>
              <Alert />
            </div>
            <form onSubmit={e => onSubmit(e)}>
              <input
                type='text'
                className='username'
                name='name'
                placeholder='Felhasználónév'
                value={name}
                required
                onChange={e => onChange(e)}
              />
              <input
                type='password'
                className='password'
                name='password'
                placeholder='Jelszó'
                value={password}
                required
                onChange={e => onChange(e)}
              />
              <input type='submit' value='Belépés' />
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login);

/* msp = function (state) {
  return {
    isAuthenticated: state.auth.isAuthenticated
  };
}

mapDispatch = (dispatch) => {
  dispatch()
} 



const connector = connect(mapStateToProps, { login });
export default connector(Login) */
