import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getHighlightedItems } from '../../actions/item';
import { setLanguage } from '../../actions/language';
import { Helmet } from 'react-helmet';

import Spinner from '../layout/Spinner';
import NavbarEN from '../layout/NavbarEN';
import FooterEN from '../layout/FooterEN';
import ItemList from '../items/ItemList';
import ItemInfo from '../items/ItemInfo';

const Home = ({
  getHighlightedItems,
  setLanguage,
  item: { currentitem, items }
}) => {
  useEffect(() => {
    getHighlightedItems();
    setLanguage('en');
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Kárai Gallery | Gallery at the Synagogue</title>
      </Helmet>
      {currentitem && <ItemInfo lang={'en'} />}
      <NavbarEN active='Home' />
      <section className='main-content-normal'>
        <div className='refimage'>
          <div className='caption'>
            <h1>
              <span className='highlight'>Karai</span> Gallery
              <br />
              at&nbsp;the <span className='highlight'>Dohány</span> Synagogue
            </h1>
            <hr />
            <div className='subtext'>
              1077&nbsp;Budapest, Wesselényi&nbsp;u.&nbsp;9.
            </div>
          </div>
        </div>
        <div className='page-title'>
          <h1>From Our Collection</h1>
          <hr />
        </div>
        <div className='highlighted-list item-list'>
          {items.length > 0 ? <ItemList list={items} /> : <Spinner />}
        </div>
      </section>
      <FooterEN />
    </Fragment>
  );
};

Home.propTypes = {
  getHighlightedItems: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  item: state.item
});

export default connect(mapStateToProps, { getHighlightedItems, setLanguage })(
  Home
);
