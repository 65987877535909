import React from 'react';
import PropTypes from 'prop-types';

const ScrollBox = ({ Component }) => {
  return (
    <div className='content-scroll-frame' id='content-layer-scroll'>
      <div
        className='content-scroll-inside'
        id='content-layer-scroll-container'
      >
        <Component />
      </div>
    </div>
  );
};

ScrollBox.propTypes = {
  Component: PropTypes.elementType.isRequired
};

export default ScrollBox;
