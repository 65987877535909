import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import PlusImage from '../../img/plus.png';

const FixItem = ({ item: { to, name, title } }) => {
  return (
    <div className='item'>
      <div className='item-image-frame'>
        <div
          className='item-image'
          style={{ imageBackground: `url(${PlusImage})` }}
        >
          <Link to={to}></Link>
        </div>
      </div>
      <div className='item-data'>
        <div className='item-data-name'>{name}</div>

        <hr />
        <div className='item-data-title'>{title}</div>
      </div>
    </div>
  );
};

FixItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default FixItem;
