import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ItemAdmin = ({ item: { _id, name, title, thumbname }, lang = 'hu' }) => {
  return (
    <div className='item'>
      <div className='item-image-frame'>
        <div
          className='item-image'
          style={{
            backgroundImage: `url('/gallery/${thumbname}')`
          }}
        >
          <Link to={`/cms/edit-item/${_id}`}></Link>
        </div>
      </div>
      <div className='item-data'>
        <div className='item-data-name'>{name[lang]}</div>

        <hr />
        <div className='item-data-title'>{title[lang]}</div>
      </div>
    </div>
  );
};

ItemAdmin.propTypes = {
  item: PropTypes.object.isRequired,
  lang: PropTypes.string
};

export default ItemAdmin;
