import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import item from './item';
import language from './language';

export default combineReducers({
  alert,
  auth,
  item,
  language
});
