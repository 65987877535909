import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import NavbarEN from '../layout/NavbarEN';
import FooterEN from '../layout/FooterEN';
import ScrollBox from '../layout/ScrollBox';
import AboutUsContent from './contents/AboutUsContent';

const AboutUs = () => {
  return (
    <Fragment>
      <Helmet>
        <title>About Us | Kárai Galéria</title>
      </Helmet>
      <NavbarEN active='About Us' />
      <section className='main-content-scroll rolunk-bg'>
        <section className='content-box'>
          <h1>About Us</h1>
          <hr />
          <ScrollBox Component={AboutUsContent} />
        </section>
      </section>
      <FooterEN />
    </Fragment>
  );
};

export default AboutUs;
