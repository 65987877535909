import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearItem } from '../../actions/item';

const ItemInfo = ({ currentitem, clearItem, language }) => {
  const [itemData, setItemData] = useState({
    name: '',
    lifespan: '',
    title: '',
    technique: '',
    dimensions: '',
    imagename: null,
    thumbname: null
  });
  useEffect(() => {
    setItemData({
      name:
        currentitem && currentitem.name ? currentitem.name[language.code] : '',
      lifespan: currentitem && currentitem.lifespan ? currentitem.lifespan : '',
      title:
        currentitem && currentitem.title
          ? currentitem.title[language.code]
          : '',
      technique:
        currentitem && currentitem.technique
          ? currentitem.technique[language.code]
          : '',
      dimensions:
        currentitem && currentitem.dimensions
          ? currentitem.dimensions[language.code]
          : '',
      imagename:
        currentitem && currentitem.imagename ? currentitem.imagename : '',
      thumbname:
        currentitem && currentitem.thumbname ? currentitem.thumbname : ''
    });
  }, [currentitem]);

  /* VARIABLES */
  const {
    name,
    lifespan,
    title,
    technique,
    dimensions,
    imagename,
    thumbname
  } = itemData;

  const closeItem = e => {
    clearItem();
  };

  return (
    <div className='item-info'>
      <div className='item-info-close' onClick={e => closeItem()}>
        X
      </div>
      <div className='item-info-header'>
        <div className='item-info-name'>{name}</div>
        <div className='item-info-lifespan'>{lifespan}</div>
      </div>
      <div
        className='item-info-image'
        style={imagename && { backgroundImage: `url('/gallery/${imagename}')` }}
      ></div>
      <div className='item-info-footer'>
        <div className='item-info-title'>
          {title}
          <hr />
        </div>
      </div>
    </div>
  );
};

ItemInfo.propTypes = {
  currentitem: PropTypes.object.isRequired,
  clearItem: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  currentitem: state.item.currentitem,
  language: state.language
});

export default connect(mapStateToProps, { clearItem })(ItemInfo);
