import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const BackAdmin = ({ title }) => {
  return (
    <Fragment>
      <header className='page-header'>
        <div className='page-back'>
          <Link to='/cms/admin' className='back'>
            Vissza
          </Link>
        </div>
        <div className='page-title'>{title}</div>
      </header>
    </Fragment>
  );
};

BackAdmin.propTypes = {
  title: PropTypes.string.isRequired
};

export default BackAdmin;
