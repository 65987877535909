import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import NavbarHU from '../layout/NavbarHU';
import FooterHU from '../layout/FooterHU';
import ScrollBox from '../layout/ScrollBox';
import KapcsolatContent from './contents/KapcsolatContent';

const Kapcsolat = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Kapcsolat | Kárai Galéria</title>
      </Helmet>
      <NavbarHU active='Kapcsolat' />
      <section className='main-content-scroll kapcsolat-bg'>
        <section className='content-box'>
          <h1>Kapcsolat</h1>
          <hr />
          <ScrollBox Component={KapcsolatContent} />
        </section>
      </section>
      <FooterHU />
    </Fragment>
  );
};

export default Kapcsolat;
