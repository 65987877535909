import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getItems } from '../../actions/item';
import { setLanguage } from '../../actions/language';
import { Helmet } from 'react-helmet';

import Spinner from '../layout/Spinner';
import NavbarEN from '../layout/NavbarEN';
import FooterEN from '../layout/FooterEN';
import ItemList from '../items/ItemList';
import ItemInfo from '../items/ItemInfo';

const Gallery = ({ getItems, setLanguage, item: { currentitem, items } }) => {
  useEffect(() => {
    getItems();
    setLanguage('en');
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>From Our Collection | Kárai Gallery</title>
      </Helmet>
      {currentitem && <ItemInfo lang={'en'} />}
      <NavbarEN active='Gallery' />
      <section className='main-content-normal'>
        <div className='page-title'>
          <h1>From Our Collection</h1>
          <hr />
        </div>
        <div className='item-list'>
          {items.length > 0 ? <ItemList list={items} /> : <Spinner />}
        </div>
      </section>
      <FooterEN />
    </Fragment>
  );
};

Gallery.propTypes = {
  getItems: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  item: state.item
});

export default connect(mapStateToProps, { getItems, setLanguage })(Gallery);
