import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getItems } from '../../actions/item';
import { setAlert } from '../../actions/alert';
import { Helmet } from 'react-helmet';

import NavbarAdmin from '../layout/NavbarAdmin';
import Spinner from '../layout/Spinner';
import Item from '../items/ItemAdmin';
import FixItem from '../items/FixItem';

const Admin = ({ getItems, item: { items, loading } }) => {
  useEffect(() => {
    getItems();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Kárai Galéria Admin</title>
      </Helmet>
      <div className='admin'>
        <NavbarAdmin />
        <section className='admin-main-content'>
          <section className='admin-highlighted'>
            <h1>Főoldali elemek</h1>
            <div id='highlighted-item-container'>
              <Fragment>
                {loading ? (
                  <Spinner />
                ) : items.length > 0 ? (
                  items
                    .filter(item => item.highlight)
                    .sort((a, b) => b.highlight.weight - a.highlight.weight)
                    .map(hitem => <Item key={hitem._id} item={hitem} />)
                ) : (
                  <div>Nincsenek tételek</div>
                )}
              </Fragment>
            </div>
          </section>
          <section className='item-list' id='admin-items'>
            <Fragment>
              <FixItem
                item={{
                  to: '/cms/create-item',
                  name: 'Új tétel létrehozása',
                  title: ''
                }}
              />
              {loading ? (
                <Spinner />
              ) : items.length > 0 ? (
                items.map(item => <Item key={item._id} item={item} />)
              ) : (
                <Fragment></Fragment>
              )}
            </Fragment>
          </section>
        </section>
      </div>
    </Fragment>
  );
};

Admin.propTypes = {
  getItems: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  item: state.item
});

export default connect(mapStateToProps, { getItems })(Admin);
