import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getItemById } from '../../actions/item';

import ItemForm from '../items/ItemForm';
import NavbarAdmin from '../layout/NavbarAdmin';
import Spinner from '../layout/Spinner';

import '../../styles/Basic.css';
import '../../styles/Admin.css';

const EditItem = ({ item, getItemById, match }) => {
  useEffect(() => {
    getItemById(match.params.id);
  }, []);

  return (
    <Fragment>
      <div className='admin'>
        <NavbarAdmin />
        <section className='admin-main-content'>
          {item.currentitem === null ? (
            <Spinner />
          ) : (
            <ItemForm
              titleText={`${item.currentitem.name.hu} - ${item.currentitem.title.hu}`}
            />
          )}
        </section>
      </div>
    </Fragment>
  );
};

EditItem.propTypes = {
  getItemById: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  item: state.item
});

export default connect(mapStateToProps, { getItemById })(EditItem);
